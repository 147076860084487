import * as React from "react"
import { useState } from "react";
import { FORMSPREE_ENDPOINT } from "../../utils/constants";
import "./contact-form.scss";

const interestsList = {
  sales: [
    "Buying a Yacht / Boat",
    "Selling a Yacht / Boat",
    "Building a Yacht/ Boat"
  ],
  management: [
    "Full Yacht Managment",
    "Tailored Yacht Managment",
    "Project Yacht Management"
  ],
  charters: [
    "Sailing Yacht Charters",
    "Motor Yacht  Charters",
    "Boat Charters"
  ],
  services: [
    "Professional Crew",
    "Cleaning & Detailing",
    "Installation & Repairs",
    "International shipping & Logistics",
    "Yacht Delivery"
  ],
}

const DEFAULT_INTEREST = "default";

const ContactForm = ({ pagePath }) => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [interest, setInterest] = useState(DEFAULT_INTEREST);
  const [message, setMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const handleSubmitted = () => {
    setSubmitted(true);
    setName(null);
    setPhone(null);
    setEmail(null);
    setInterest(DEFAULT_INTEREST);
    setMessage(null);
  }

  let interests = interestsList[pagePath];
  if (!interests) {
    interests = [];
    Object.keys(interestsList).forEach(function (key, index) {
      interests.push(...interestsList[key]);
    });
  }

  return (
    <form
      onSubmit={handleSubmitted}
      className="my-contact-form"
      action={FORMSPREE_ENDPOINT}
      method="POST">
      <div className="my-contact-form-content">
        <div className="my-contact-form-fields">
          <input
            placeholder="Name"
            name="name"
            type="text"
            value={name}
            onChange={(event) => setName(event.target.value)} />
          <input
            placeholder="Phone"
            name="phone"
            type="tel"
            value={phone}
            onChange={(event) => setPhone(event.target.value)} />
          <input
            placeholder="Email"
            name="email"
            type="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)} />
          <select
            placeholder="Interested In"
            name="interest"
            type="select"
            defaultValue={interest}
            className={interest === DEFAULT_INTEREST ? "my-contact-form-fields__placeholder" : ""}
            onChange={(event) => setInterest(event.target.value)}>
            <optgroup>
              <option value={DEFAULT_INTEREST} disabled hidden>Interested In</option>
              {interests.map(option => <option key={option} value={option}>{option}</option>)}
            </optgroup>
          </select>
          <textarea
            rows="5"
            name="message"
            placeholder="Write your message here"
            value={message}
            onChange={(event) => setMessage(event.target.value)} />
        </div>
      </div>
      <div className="my-contact-form-actions">
        <input
          disabled={!name || !phone || !email || !message || interest === DEFAULT_INTEREST}
          className="my-contact-form__submit"
          type="submit"
          value="Send message" />
        {submitted && <span className="my-contact-form__message">Thank you, we will contact you shortly.</span>}
      </div>
    </form >
  );
}

export default ContactForm;