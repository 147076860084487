import React from "react";

import "./arrow.scss";
import {Link} from "gatsby";
import arrowIcon from "../../images/arrow-gray.svg";

export const Arrow = ({url, alignBottom = true}) => {
  return (
    <Link className={`my-arrow ${alignBottom ? "my-arrow--bottom" : ""}`} to={`#${url??""}`}>
      <img src={arrowIcon} alt="Go down"/>
    </Link>
  );
}
