import React from "react";
import "./page-section.scss";
import {SectionImage} from "../section-image/section-image";

export const PageSection = ({id, image, imageWP, largeImage, largeImageWP, title, children, hasMargin = true, isReverse}) => {
  return (
    <section
      id={id}
      className={`my-page-section ${!hasMargin ? "my-page-section--no-margin" : ""}
      ${isReverse ? "my-page-section--reverse" : ""}`}
    >
      <div className={`my-page-section-img-wrapper ${isReverse ? "my-page-section-img-wrapper--reverse" : ""}`}>
        <SectionImage
          isReverse={isReverse}
          image={image}
          imageWP={imageWP}
          largeImage={largeImage}
          largeImageWP={largeImageWP}
          title={title} />
      </div>
      <div className="my-page-section-content">
        <h2 className="my-page-section__title">{title}</h2>
        <div className="my-page-section__children">
          {children}
        </div>
      </div>
    </section>
  );
};
