import React, {useState} from "react";

import "./quote.scss";
import openQuoteIcon from "../../images/open-quote.svg";
import closeQuoteIcon from "../../images/close-quote.svg";
import {Parallax} from "react-scroll-parallax";
import handleViewport from "react-in-viewport";

const QuoteRaw = ({id, text, highlightedText, additionalText, author, inViewport, forwardedRef}) => {
  const [show, setShow] = useState(false);
  if (inViewport && !show) {
    setShow(true);
  }

  return (
    <div id={id} className={`my-quote ${show ? "my-quote--show" : ""}`} ref={forwardedRef}>
      <div className="my-quote-content">
        <img className="my-quote__open" src={openQuoteIcon} alt="Open quote"/>
        <blockquote className="my-quote__text">{text} <span>{highlightedText}</span> {additionalText}</blockquote>
        <img className="my-quote__close" src={closeQuoteIcon} alt="Close quote"/>
      </div>
      {author && <span className="my-quote__author">-{author}</span>}
    </div>
  );
}

export const Quote = handleViewport(QuoteRaw, {rootMargin: "-100px"}, { disconnectOnLeave: true });
