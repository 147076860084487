import * as React from "react"
import SEO from "../components/SEO/SEO";
import { Layout } from "../components/layout/layout";
import { Quote } from "../components/quote/quote";
import { PageSection } from "../components/page-section/page-section";
import image1 from "../images/services-1-small.png";
import image2 from "../images/services-2-small.png";
import image3 from "../images/services-3-small.png";
import image4 from "../images/services-4-small.png";
import image5 from "../images/services-5-small.png";
import image1WP from "../images/services-1-small.webp";
import image2WP from "../images/services-2-small.webp";
import image3WP from "../images/services-3-small.webp";
import image4WP from "../images/services-4-small.webp";
import image5WP from "../images/services-5-small.webp";
import largeImage1 from "../images/services-1.png";
import largeImage2 from "../images/services-2.png";
import largeImage3 from "../images/services-3.png";
import largeImage4 from "../images/services-4.png";
import largeImage5 from "../images/services-5.png";
import largeImage1WP from "../images/services-1.webp";
import largeImage2WP from "../images/services-2.webp";
import largeImage3WP from "../images/services-3.webp";
import largeImage4WP from "../images/services-4.webp";
import largeImage5WP from "../images/services-5.webp";

import "./services.scss";
import { Arrow } from "../components/arrow/arrow";
import FormContainer from "../components/form-container/form-container";

const ServicesPage = () => {
  return (
    <main>
      <SEO
        title="Services"
        pathname="/services"
        description="Clients come to Miami Yachting because they find all the solutions for their yachting needs in just one place"
        keywords={["professional", "crew", "cleaning", "detailing", "installations", "repairs", "shipping", "logistics", "delivery"]}
      />
      <Layout page="services">
        <div className="my-services">
          <Quote
            author="Capt. Randy A. Gomez"
            text="Clients come to Miami Yachting because they"
            highlightedText="find all the solutions for their yachting needs in just one place"
          />
          <Arrow alignBottom={false} url="professional-crew" />
          <PageSection id="professional-crew" image={image1} imageWP={image1WP} largeImage={largeImage1} largeImageWP={largeImage1WP} title="Professional Crew" isReverse={true}>
            <p className="my-services-section">Crew are one of the most important factors when it comes to running a safe and successfully yacht. A professional crew dedicated to providing the highest standard of service is an essential element of the ultimate onboard experience.</p>
            <p className="my-services-section">At Miami Yachting, we built a long and vast network of highly experienced captains, mates, stewardess and chefs available on speed dial ready to handle your vessel and meet your expectations.</p>
          </PageSection>
          <Arrow alignBottom={false} url="clean-and-detailing" />
          <PageSection id="clean-and-detailing" image={image2} imageWP={image2WP} largeImage={largeImage2} largeImageWP={largeImage2WP} title="Cleaning and detailing">
            <p className="my-services-section">Whether it’s a post-party detailing, a seasonal-cleaning, a regular yacht wash and wax/polish, we do it all for you. We utilize only the most innovative products to ensure your yacht’s finish is flawless every time. Here are just a few of our detailing services we offer for your yacht.</p>
            <ul className="my-services-list">
              <li>Wash down</li>
              <li>Waxing and polishing</li>
              <li>Interior cleaning</li>
              <li>Chrome and metals polishing</li>
              <li>Wood maintenance (sanding/recaulking/refinishing)</li>
              <li>Glass polish and mildew cleaning</li>
              <li>Canvas and vinyl cleaning and maintenance</li>
              <li>Engine room adn bilges detailing</li>
              <li>Bottom cleaning</li>
            </ul>
          </PageSection>
          <Arrow alignBottom={false} url="installations-and-repair" />
          <PageSection id="installations-and-repair" image={image3} imageWP={image3WP} largeImage={largeImage3} largeImageWP={largeImage3WP} title="Installations and repairs" isReverse={true}>
            <p className="my-services-section">Our yacht repairs & services comprehends the most needed technical work from troubleshooting to installations. Our services division can offer a wide variety of yacht services, repairs and maintenance custom tailored to the boat’s owner needs.</p>
            <ul className="my-services-list">
              <li>Diesel, Outboard and inboard engines services and repairs.</li>
              <li>Generators services and repairs.</li>
              <li>Air conditioning services and repair.</li>
              <li>Onboard system troubleshooting.</li>
              <li>Electrical and plumbing.</li>
              <li>Yacht painting, fiber glass, gel coat finishing and bottom paint.</li>
              <li>Teak overhaul.</li>
              <li>Upholstery.</li>
              <li>Refit projects.</li>
              <li>Yacht sanitization services.</li>
            </ul>
          </PageSection>
          <Arrow alignBottom={false} url="international-shipping" />
          <PageSection id="international-shipping" image={image4} imageWP={image4WP} largeImage={largeImage4} largeImageWP={largeImage4WP} title="International Shipping and Logistics">
            <p className="my-services-section">International shipping is the most difficult and time-consuming job in boat purchasing.</p>
            <p className="my-services-section">Shipping method, date, and cost should be planned at the very beginning of the purchasing process because it takes a long time to arrange appropriate shipping for the best date and cost. The first thing we have to determine is a shipping method. Based on the size of the shipment, we can determine which shipping method is appropriate and economical.</p>
            <p className="my-services-section">At Miami Yachting, we have our internal shipping network, so we are confident that we can find you the best price and date for your boat.</p>
          </PageSection>
          <Arrow alignBottom={false} url="yacht-delivery" />
          <PageSection id="yacht-delivery" image={image5} imageWP={image5WP} largeImage={largeImage5} largeImageWP={largeImage5WP} title="Yacht Delivery" hasMargin={false} isReverse={true}>
            <p className="my-services-section">Miami Yachting and its team provide the best in yacht delivery service.</p>
            <p className="my-services-section">Our professional captains and crew are experienced in both sailing and power yachts up to 120ft from the east coast to the Caribbean and back, we can bring your yacht to that place you have always dreamed.</p>
          </PageSection>
          <Arrow url="services-quote" />
          <Quote
            id="services-quote"
            text="Our passion,"
            highlightedText="your peace of mind."
          />
          <FormContainer topic="Service" pagePath="services" />
        </div>
      </Layout>
    </main>
  )
}

export default ServicesPage;
