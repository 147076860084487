import React, {useState} from "react";
import handleViewport from 'react-in-viewport';
import "./section-image.scss";
import {Parallax} from "react-scroll-parallax";

const SectionImageRaw = ({inViewport, forwardedRef, image, imageWP, largeImage, largeImageWP, title, isReverse}) => {
  const [showImage, setShowImage] = useState(false);
  if (inViewport && !showImage) {
    setShowImage(true);
  }

  const parallaxArray = isReverse ? [-10, 0] : [10, 0]

  return (
    <div ref={forwardedRef}>
      <div
        className={`my-section-image__cover ${showImage ? "my-section-image__cover--hide" : ""} ${isReverse ? "my-section-image__cover--reverse" : ""}`} />
      <div className="my-section-image-container">
          <picture>
            <source media="(min-width:480px)" srcSet={largeImageWP} type="image/webp" />
            <source media="(min-width:480px)" srcSet={largeImage} />
            <source srcSet={imageWP} type="image/webp" />
            <img className={`my-section-image__img ${showImage ? "my-section-image__img--show" : ""}`} src={image} alt={title} />
          </picture>
      </div>
    </div>
  );
};

export const SectionImage = handleViewport(SectionImageRaw, {rootMargin: "-100px"}, { disconnectOnLeave: true });
